.button-bar {
  display: inline-block;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  width: 60%;
  margin: -20px 20%;
}

.button-souscrire {
  display: inline-block;
  background-color: #ffffff;
  color: #000000;
  padding: 15px 35px;
  border: 1px solid #5c317a;
  border-radius: 10px;
  text-align: center;
  white-space: nowrap;
  transition: background-color 0.3s;
  cursor: pointer;
  margin-right: 24px;
  width: 340px;
  text-align: center;
}
.button-souscrire:hover {
  background-color: #2ae6b8;
  color: #000000;
}
.button-contacte {
  display: inline-block;
  background-color: #5c317a;
  color: #ffffff;
  padding: 20px 35px;
  border: 1px solid #5c317a;
  border-radius: 10px;
  text-align: center;
  white-space: nowrap;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
  margin-right: 24px;
  width: 340px;
  text-align: center;
}
.button-contacte:hover {
  background-color: #ffffff;
  color: #5c317a;
}

.iconeBulb {
  width: 10%;
  margin-right: 12px;
}

.iconePhone {
  width: 8%;
  margin-right: 12px;
}

@media (max-width: 1670px) {
  .button-bar {
    display: block;
    text-align: center;
  }

  .button-souscrire {
    width: 100%;
    margin-bottom: 20px;
  }
  .button-contacte {
    width: 100%;
    margin-bottom: 20px;
    padding: 60px 5px;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .button-bar {
    width: 300px;
    margin-left: 15%;
  }
}
