#banner {
  background-image: url("../../Images/Rectangle_Titre.png");
  background-size: cover;
  flex-direction: row;
  height: 577px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
}

.titre {
  font-size: 62px;
  color: #5c317a;
  line-height: 86px;
  text-shadow: 4px 4px 0px #ffffff;
}

.imgTitre {
  width: 30%;
  margin-right: 10%;
  margin-bottom: -9.5%;
}

@media (max-width: 992px) {
  .container {
    height: 600px;
  }
  .titre {
    font-size: 38px;
    transform: translateX(-50%);
    line-height: 54px;
    position: relative;
    left: 50%;
  }
  .imgTitre {
    display: none;
  }
}
